<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "Schedule",
  }
</script>

<style scoped>

</style>